import placeholder from "assets/images/placeholder.jpg";
import { AboutUs } from "components/AboutUs/AboutUs";
export const About = () => {
  return (
    <>
      <img src={placeholder} style={{ width: "100vw" }} alt=""/>
      <div className="d-flex justify-content-center my-5">
        <AboutUs />
      </div>
    </>
  );
};
