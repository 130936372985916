export const COLORS = {
  primary: "#ef5231",
  text: "#f5f5f5",
  accent: "#5b5b5b",
  shadow: "#f2ede6",
  ash: "#f7f4f2",
  success: '#78be20',
  error: "#ff1500",
  warning: "#ffa91f",
};
