import { COLORS } from "constants/colors";
import carImg from "assets/images/car-pack@2x.png";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getDataByField } from "utils/requests";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import moment from "moment";

export const OrderHistory = () => {
  const { userInfo } = useSelector((state) => state.appReducer);
  const [userOrders, setUserOrders] = useState([]);

  useEffect(() => {
    if (userInfo.id) {
      getAllBookingsByUser();
    }
  }, [userInfo.id]); //Replace by userInfo.userId later

  const getAllBookingsByUser = async () => {
    const allOrders = await getDataByField(
      "bookings",
      "user_id",
      userInfo.id,
      "=="
    );
    if (allOrders && allOrders.length > 0) {
      setUserOrders(
        allOrders.map((order, idx) => {
          return idx === 0 ? { ...order, seeData: true } : order;
        })
      );
    }
  };

  const convertTo12HrFormat = (timeIn24Hr) => {
    var time = timeIn24Hr.split(':');
    var hoursStr = time[0];
    var minsStr = time[1];
    var hours = parseInt(hoursStr);
    var ampm = '';
    if (hours == 12) {
        ampm = ' PM'
    }else if (hours > 12) {
        ampm = 'PM'
        hours = hours - 12
        hoursStr = (hours < 10) ? '0' + hours.toString() : hours.toString(); 
    } else {
        if(hours === 0){hours = 12}
        hoursStr = (hours < 10) ? '0' + hours.toString() : hours.toString();
        ampm = 'AM'
    }
    return hoursStr + ':' + minsStr + ampm;
  };
  const handleShowData = (orderId) => {
    const tempOrders = userOrders.map((order) => {
      return order.order_id === orderId
        ? { ...order, seeData: !order.seeData }
        : order;
    });
    setUserOrders(tempOrders);
  };

  return (
    <div className="d-flex justify-content-center my-5">
      <div className="col-1"></div>
      <div className="col-10">
        <h1 className="fw-bold">
          <span style={{ color: COLORS.primary }}>Booking </span>
          History
        </h1>
        <p>Check your bookings and invoices</p>
        {userOrders.length > 0 ? (
          userOrders.map((order) => (
            <>
              <div
                className="d-flex align-items-center p-1 px-md-3 py-md-2 my-3 bg-light shadow-sm rounded-3"
                key={order.order_id}
              >
                <div className="col-4">
                <img
                  className="mr-4"
                  src={carImg}
                  alt="car-img"
                  width={100}
                  height={50}
                />
                </div>
                
                <div className="col-3 mb-3">
                  <p className="text-dark fw-bold mb-0">{order.service.name}</p>
                  <p className="text-secondary mb-0">&#8377;{order.total}</p>
                </div>
                <div className="col-3">
                  <div className="mb-2">  
                    <p className="text-dark fw-bold mb-0">Booking ID</p>
                    <p className="text-secondary mb-0">#{order.order_id}</p>
                  </div>
                  <div>
                    <div
                      className={`rounded-3 p-2 mb-2 text-center text-light fw-bold ${
                        order.status === "CREATED"
                          ? "bg-primary"
                          : order.status === "COMPLETED"
                          ? "bg-success"
                          : "bg-info"
                      }`}
                      style={{ fontSize: "10px", width: "80px" }}
                    >
                      {order.status}
                    </div>
                  </div>
                </div>
                <div className="col-2 text-center">
                  {order.seeData ? (
                    <FaChevronUp
                      size={20}
                      color={COLORS.accent}
                      onClick={() => handleShowData(order.order_id)}
                    />
                  ) : (
                    <FaChevronDown
                      size={20}
                      color={COLORS.accent}
                      onClick={() => handleShowData(order.order_id)}
                    />
                  )}
                </div>
              </div>
              {order.seeData && (
                <div className="d-flex" key={order.order_id}>
                  <div className="bg-light col-6 p-3 border border-secondary d-none d-md-block">
                    <p className="fs-5">
                      <span className="fw-bold">Order Id- </span>#
                      {order.order_id}
                    </p>
                    <h5 className="fw-bold">Package Information</h5>
                    <hr />
                    <p className="text-secondary mb-0 fs-6">
                      {order.service.description}
                    </p>
                    <p
                      className="fw-bold fs-5 text-capitalize"
                      style={{ color: COLORS.primary }}
                    >
                      {order.service.name}
                    </p>
                    {order.service.details.map((detail, idx) => (
                      <div className="d-flex mb-2 align-items-center" key={idx}>
                        <FiCheckCircle size={18} color={COLORS.primary} />
                        <p className="text-dark mb-0 ml-2">{detail}</p>
                      </div>
                    ))}
                  </div>
                  <div className="col-12 col-md-6 p-3 border border-secondary">
                    <h5 className="fw-bold">Contact Details</h5>
                    <hr />
                    <div className="d-flex mb-2">
                      <div className="col-4">
                        <p className="text-dark fw-bold">Name</p>
                        <p className="text-dark fw-bold">Phone</p>
                      </div>
                      <div className="col-8">
                        <p className="text-secondary">
                          {order.userData.displayName}
                        </p>
                        <p className="text-secondary">
                          {order.userData.phoneNumber.includes("+91")
                            ? order.userData.phoneNumber
                            : `+91${order.userData.phoneNumber}`}
                        </p>
                      </div>
                    </div>
                    <h5 className="fw-bold mt-4">Vehicle Details</h5>
                    <hr />
                    <div className="d-flex mb-2">
                      <div className="col-4">
                        <p className="text-dark fw-bold">Car Type</p>
                        <p className="text-dark fw-bold">Car Brand</p>
                        <p className="text-dark fw-bold">Car Model</p>
                      </div>
                      <div className="col-8">
                        <p className="text-secondary text-capitalize">
                          {order.variant.vehicle_type ? order.variant.vehicle_type : "-"}
                        </p>
                        <p className="text-secondary text-capitalize">
                          {order.vehicle.brand ? order.vehicle.brand : "-"}
                        </p>
                        <p className="text-secondary text-capitalize">
                          {order.vehicle.model ? order.vehicle.model : "-"}
                        </p>
                      </div>
                    </div>
                    <h5 className="fw-bold mt-4">Booking Details</h5>
                    <hr />
                    <div className="d-flex ">
                      <div className="col-4">
                        <p className="text-dark fw-bold">Date</p>
                      </div>
                      <div className="col-8">
                        <p className="text-secondary">
                          {new Date(order.date.full_date).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </p>
                      </div>
                      </div>
                      <div className="d-flex">
                      {order.date.time && (
                        <>
                          <div className="col-4">
                            <p className="text-dark fw-bold">Time</p>
                          </div>
                          <div className="col-8">
                            <p className="text-secondary">
                              {convertTo12HrFormat(order?.date.time)}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                    {order.vendor && (
                      <>
                        <div className="d-flex">
                          <div className="col-4">
                            <p className="text-dark fw-bold">Captain</p>
                            <p className="text-dark fw-bold">Status</p>
                          </div>
                          <div className="col-8">
                            <p className="text-secondary text-capitalize">
                              {order.vendor.vendor_name}
                            </p>
                            <p className={`rounded-3 p-2 mb-2 text-center text-light fw-bold ${
                              order.status === "CREATED"
                                ? "bg-primary"
                                : order.status === "COMPLETED"
                                ? "bg-success"
                                : "bg-info"
                            }`}
                            style={{ fontSize: "10px", width: "80px" }}
                            >
                              {order.status} 
                            </p>
                          </div>
                        
                          <div className="col-8">
                            
                          </div>
                        </div>
                      </>
                    )}
                    <h5 className="fw-bold pt-4">Pricing Information</h5>
                    <hr />
                    <div className="d-flex mb-2">
                      <div className="col-9">
                        <p
                          className="fw-bold"
                          style={{ color: COLORS.primary }}
                        >
                          {order.service.name}
                        </p>
                        {order.service.electricity_charges && (
                          <p className="text-secondary fw-bold">
                            Electricty Charges
                          </p>
                        )}
                        {order.addons &&
                          order.addons.length > 0 &&
                          order.addons.map((addon) => (
                            <p className="text-dark" key={addon.addon_name}>
                              {addon.addon_name} (Addon)
                            </p>
                          ))}
                        {order.discount && order.discount !== 0 ? (
                          <p className="text-secondary fw-bold">Discount</p>
                        ) : null}
                      </div>
                      <div className="col-3 text-right">
                        <p className="text-secondary fw-bold">
                          &#8377;{order.variant.actual_price}
                        </p>
                        {order.service.electricity_charges && (
                          <p className="text-secondary fw-bold">
                            &#8377;{order.service.electricity_charges}{" "}
                          </p>
                        )}
                        {order.addons &&
                          order.addons.length > 0 &&
                          order.addons.map((addon) => (
                            <p
                              className="text-secondary fw-bold"
                              key={addon.addon_name}
                            >
                              &#8377;{addon.actual_price}
                            </p>
                          ))}
                        {order.discount && order.discount !== 0 ? (
                          <p className="text-secondary fw-bold">
                            - &#8377;{order.discount}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex mb-2">
                      <div className="col-9">
                        <p className="text-dark fw-bold fs-4">Total</p>
                      </div>
                      <div className="col-3 text-right">
                        <p className="text-dark fw-bold fs-4">
                          &#8377;{order.total}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))
        ) : (
          <h1 className="text-danger">
            Oops! You have not placed any bookings yet.
          </h1>
        )}
      </div>

      <div className="col-1"></div>
    </div>
  );
};
