import React from "react";

import { FaFacebook, FaWhatsapp, FaYoutube, FaInstagram } from "react-icons/fa";
import Logo from "../../assets/images/logo@2x.png";

import { NavLink } from "react-router-dom";
import { COLORS } from "constants/colors";

export const Footer = () => {
  return (
    <>
      
      <div
        className="d-flex flex-wrap justify-content-around align-items-center p-5"
        style={{ backgroundColor: COLORS.shadow }}
      >
        <img src={Logo} alt="" height={80} width={80} />
        <div>
          <h4 className="mb-4">Reach out to us</h4>
          <div className="d-flex align-items-center">
          <a href="https://wa.me/8695971777">
              <FaWhatsapp color="green" size={30} className="mr-2" role="button" />
            </a>
            <a href="https://www.facebook.com/fixuji/">
              <FaFacebook
                color="blue"
                size={30}
                className="mr-2"
                role="button"
              />
            </a>
            <a href="https://www.instagram.com/fixuji_services/?hl=en">
              <FaInstagram size={30} color="red" role="button" />
            </a>
          </div>
        </div>
        <div className="col-md-5 text-start my-4 my-sm-0">
          <h4 className="mb-4">Useful Links</h4>
          <div className="d-flex flex-wrap">
            <NavLink
              to=""
              className="text-secondary mr-4 text-uppercase"
              style={{ textDecoration: "none" }}
            >
              Home
            </NavLink>
            <NavLink
              to="packages"
              className="text-secondary mr-4 text-uppercase"
              style={{ textDecoration: "none" }}
            >
              Booking
            </NavLink>
            {/* <NavLink
              to="membership"
              className="text-secondary mr-4 text-uppercase"
              style={{ textDecoration: "none" }}
            >
              Membership
            </NavLink>
            <NavLink
              to="orders"
              className="text-secondary mr-4 text-uppercase"
              style={{ textDecoration: "none" }}
            >
              Orders
            </NavLink> */}
            <NavLink
              to="aboutus"
              className="text-secondary mr-4 text-uppercase"
              style={{ textDecoration: "none" }}
            >
              About Us
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container-fluid text-center justify-content-between align-items-center col-md-12 pt-3 pb-2 bg-dark">
        <p style={{ color: "#fff" }}>
        Copyright &copy; 2024
        </p>
        <p style={{ color: "#fff" }}>
        FIXUJI- DOMESTIC SERVICE 
        </p>
        <p style={{ color: "#fff" }}>
          Contact us at 9830123562
        </p>
      </div>
    </>
  );
};
