import { Footer } from "components/Footer/Footer";
import { Navigator } from "components/Navigator/Navigator";

export const Layout = ({ children }) => {
  return (
    <>
      <Navigator />
      {children}
      <Footer />{" "}
    </>
  );
};
