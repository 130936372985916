import { COLORS } from "constants/colors";
import { useEffect, useState } from "react";
import { FaPhoneAlt, FaUserAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { login, logout, setProfileData } from "redux/slices";
import Logo from "../../assets/images/logo.png";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import axios from "axios";
import { getDataByField } from "utils/requests";
export const Navigator = () => {
  const { isLoggedIn } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState();
  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleLogInLogout = () => {
    if (isLoggedIn) {
      dispatch(logout());
      localStorage.clear();
      navigate("/", { replace: true });
    } else {
      setShowLoginModal(true);
    }
  };

  const handleOtpSentOrVerify = async () => {
    if (!otpSent) {
      setDisabled(true);
      let fourDigitOtp = Math.floor(1000 + Math.random() * 9000);
      setGeneratedOtp(fourDigitOtp);
      let sms = `Dear user. Please use ${fourDigitOtp} as your OTP. Thanks and regards, FIXUJI.`;
      await axios.post(
        "https://us-central1-fixuji-customer.cloudfunctions.net/sendCustomSms",
        {
          phoneNumber: mobileNumber,
          sms,
        }
      );
      setOtpSent(true);
      setDisabled(false);
    } else {
      if (+otp === +generatedOtp) {
        const response = await getDataByField(
          "userinfo",
          "phoneNumber",
          mobileNumber,
          "=="
        );
        const userData = response[0];
        setOtp('');
        setShowLoginModal(false);
        setOtpSent(false);
        setMobileNumber("");
        dispatch(login());
        dispatch(setProfileData(userData));
        localStorage.setItem("userId", userData.id);
        localStorage.setItem("userName", userData.displayName);
        localStorage.setItem("userEmail", userData.email);
        localStorage.setItem("userPhone", userData.phoneNumber);
        setOtpVerified(true);
      } else {
        setShowError("Entered OTP is wrong!");
      }
    }
  };

  useEffect(() => {
    if (otpVerified) {
      setShowLoginModal(false);
    }
  }, [otpVerified]);

  return (
    <>
      <Modal
        open={showLoginModal}
        onClose={() => {
          setShowLoginModal(false);
          setOtpSent(false);
          setMobileNumber("");
        }}
        center
        showCloseIcon={false}
      >
        <div className="rounded-3 p-2">
          <div className="d-flex justify-content-between flex-wrap">
            <h3 className="modal-title text-center">Login</h3>
            <button
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShowLoginModal(false);
                setOtpSent(false);
                setMobileNumber("");
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="my-4">
            <h5 className="text-secondary">Enter Mobile Number</h5>
            <input
              type="text"
              className="form-control"
              value={mobileNumber}
              onChange={(event) => {
                setMobileNumber(event.target.value.replace(/[^0-9]/g, ""));
              }}
              maxLength={10}
            />
          </div>
          {otpSent && (
            <div className="my-4">
              <h5 className="text-secondary">Enter OTP</h5>
              <input
                type="text"
                className="form-control"
                value={otp}
                onChange={(event) => {
                  setOtp(event.target.value.replace(/[^0-9]/g, ""));
                }}
                maxLength={4}
              />
            </div>
          )}

          <div className="my-4">
            {otpSent ? (
              <button
                disabled={otp.length < 4 || disabled}
                type="button"
                className="btn p-2 rounded-3 text-light fw-bold"
                style={{ backgroundColor: COLORS.primary }}
                onClick={handleOtpSentOrVerify}
              >
                Login
              </button>
            ) : (
              <button
                disabled={mobileNumber.length < 10}
                type="button"
                className="btn p-2 rounded-3 text-light fw-bold"
                style={{ backgroundColor: COLORS.primary }}
                onClick={handleOtpSentOrVerify}
              >
                Send OTP
              </button>
            )}
          </div>
          {showError && <p className="text-danger fw-bold">{showError}</p>}
        </div>
      </Modal>
      <div className="d-flex py-2 px-4 bg-dark justify-content-between align-items-center flex-wrap">
        <div className="d-flex text-white px-4">
          <div className="" href="#home" style={{ paddingRight: 10 }}>
            FIXUJI- DOMESTIC SERVICE
          </div>
        </div>
        <div className="d-flex text-white px-4 mt-3 mt-sm-0">
          <div className="mr-3" role="button" onClick={handleLogInLogout}>
            {isLoggedIn ? "Logout" : "Login"}
          </div>
          {isLoggedIn && (
            <>
              <div style={{ width: "1px" }} className="bg-light"></div>
              <div
                className="d-flex align-items-center ml-3"
                onClick={() => navigate("/profile")}
                role="button"
              >
                <FaUserAlt size={20} color={COLORS.primary} />
                <p className="mb-0 ml-2">Profile</p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="d-flex flex-wrap py-2 px-4 justify-content-between align-items-center">
        <div className="navbar-brand px-4" href="#home">
          <img src={Logo} alt="" height={50} width={50} />
        </div>
        <div className="d-flex align-items-center text-secondary">
          <NavLink
            to=""
            className="text-secondary mr-4 text-uppercase"
            style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
          >
            Home
          </NavLink>
          <NavLink
            to="packages"
            className="text-secondary mr-4 text-uppercase"
            style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
          >
            Booking
          </NavLink>
          {/*<NavLink
            to="membership"
            className="text-secondary mr-4 text-uppercase"
            style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
          >
            Membership
          </NavLink>*/}
          {isLoggedIn && (
            <NavLink
              to="orders"
              className="text-secondary mr-4 text-uppercase"
              style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
            >
              History
            </NavLink>
          )}

          <NavLink
            to="aboutus"
            className="text-secondary mr-4 text-uppercase"
            style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
          >
            About Us
          </NavLink>
        </div>
      </div>
    </>
  );
};

const activeStyle = {
  textDecoration: "none",
  borderBottom: `2px solid ${COLORS.primary}`,
};

const inactiveStyle = {
  textDecoration: "none",
};
