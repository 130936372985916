import { COLORS } from "constants/colors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDataByField } from "utils/requests";

export const Profile = () => {
  const { userInfo } = useSelector((state) => state.appReducer);
  const navigate = useNavigate();
  console.log(userInfo);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalServices, setTotalServices] = useState(0);

  useEffect(() => {
    if(userInfo.id){
      fetchUserServiceData();
    }
  }, [userInfo.id]);

  const fetchUserServiceData = async () => {
    const orderRes = await getDataByField(
      "bookings",
      "user_id",
      userInfo.id,
      "=="
    );
    setTotalOrders(orderRes.length);
    let services = [];
    orderRes.forEach((order) => {
      services.push(order.service.name);
    });
    setTotalServices([...new Set(services)].length); 
  };

  return (
    <div className="p-2 p-sm-5 m-0 m-sm-5">
      <h1>
        <span style={{ color: COLORS.primary }}>Hi </span>
        {userInfo.displayName}
      </h1>
      <p>Welcome to FIXUJI</p>
      <div className="d-flex flex-wrap justify-content-center mt-5">
        <div
          className="bg-warning col-4 mx-2 p-5 d-flex justify-content-between align-items-center rounded-3 col-12 col-sm-4 my-2"
          role="button"
        >
          <div>
            <p className="text-light fs-5 mb-0">No Of</p>
            <p className="text-light fw-bold fs-4">WASH</p>
          </div>
          <span className="text-light fw-bold fs-3">{totalOrders}</span>
        </div>
        <div
          className="bg-danger col-4 mx-2 p-5 d-flex justify-content-between align-items-center rounded-3 col-12 col-sm-4 my-2"
          role="button"
        >
          <div>
            <p className="text-light fs-5 mb-0">No Of</p>
            <p className="text-light fw-bold fs-4">SERVICE</p>
          </div>
          <span className="text-light fw-bold fs-3">{totalServices}</span>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-center mt-5">
        <div
          className="bg-secondary col-4 mx-2 p-5 d-flex justify-content-between align-items-center rounded-3 col-12 col-sm-4 my-2"
          role="button"
          onClick={() => navigate('/orders')}
        >
          <div>
            <p className="text-light fs-5 mb-0">BOOKINGS</p>
            <p className="text-light fw-bold fs-4">View Your Bookings</p>
          </div>
        </div>
        <div
          className="bg-secondary col-4 mx-2 p-5 d-flex justify-content-between align-items-center rounded-3 col-12 col-sm-4 my-2"
          role="button"
          onClick={() => navigate("/profile/my-profile")}
        >
          <div>
            <p className="text-light fs-5 mb-0">PROFILE</p>
            <p className="text-light fw-bold fs-4">Manage Your Profile Infos</p>
          </div>
        </div>
      </div>
    </div>
  );
};
