import { useEffect, useState } from "react";
import Car_pack from "assets/images/car-pack@2x.png";
import { FiCheckCircle, FiArrowRightCircle, FiCircle } from "react-icons/fi";
import placeholder from "assets/images/placeholder.jpg";
import { COLORS } from "constants/colors";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDataByField } from "utils/requests";

export const Booking = () => {
  const [services, setServices] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState();
  const navigate = useNavigate();  
  const {isLoggedIn} = useSelector(state => state.appReducer);

  const getAllServices = async() => {
    const allServices = await getDataByField("services", 'app_display', true, '==');
    const servicesInclusive = allServices.map((service) => {
      const variants = [...service.variant];
      variants.sort((v1, v2) => +v1.actual_price - +v2.actual_price);
      return {
        ...service,
        startingPrice: variants[0].actual_price,
        image: service.name.includes("Bike") ? Car_pack : Car_pack,
      };
    });
    setServices(servicesInclusive);
  };


  useEffect(() => {
    getAllServices();
  },[]);

  const handleChangeActivePackage = (service, vehicle) => {
    const packageDet = {
      packageName: service,
      vehicleType: vehicle,
    };
    setSelectedPackage(packageDet);
    const newServiceArr = services.map((s) => {
      if (s.name !== service) {
        return {
          ...s,
          variant: s.variant.map((v) => {
            return { ...v, active: false };
          }),
        };
      } else {
        return {
          ...s,
          variant: s.variant.map((v) => {
            return v.vehicle_type === vehicle
              ? { ...v, active: !v.active }
              : { ...v, active: false };
          }),
        };
      }
    });
    setServices(newServiceArr);
  };

  const handleStartBooking = () => {
    navigate('/packages/new-booking', {
        state: {
          selectedPackage
        }
    });
  }

  return (
    <>
      <img src={placeholder} style={{ width: "100vw" }} alt=""/>
      <div className="d-flex justify-content-center align-items-center text-center my-5">
        <div>
          <h1>
            <span style={{ color: COLORS.primary }}>Our</span> Packages
          </h1>
          <p className="text-secondary">Select a Package to Book Now</p>
        </div>
      </div>
      <div className="row">
        <div className="col-1"></div>
        <div className="d-flex flex-wrap justify-content-center col-9 col-sm-10">
          {services.map((service) => (
            <div className="card shadow p-3 m-4 col-md-5 col-12 rounded-3" key={service.name}>
              <div style={{ height: "95%" }}>
                <div className="text-center mb-2">
                  <p className="text-secondary mb-0">{service.description}</p>
                  <h3 style={{ color: COLORS.primary }}>{service.name}</h3>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <div className="col-xs-12">
                    {service.details.map((detail,idx) => (
                      <div className="d-flex mb-2 align-items-center" key={idx}>
                        <FiCheckCircle size={18} color={COLORS.primary} />
                        <p className="text-dark mb-0 ml-2">{detail}</p>
                      </div>
                    ))}
                  </div>
                  <img
                  className="d-none d-sm-block"
                    src={service.image}
                    width={120}
                    height={80}
                    alt="service-img"
                  />
                </div>
                <p className="text-secondary mb-0 mt-2">Select Car Type</p>
                {service.variant.map((v) => (
                  <div className="d-flex align-items-center justify-content-between" key={v.vehicle_type}>
                    <div className="d-flex align-items-center my-2">
                      <FiArrowRightCircle size={24} />
                      <div className="ml-2">
                        <p className="mb-0">{v.vehicle_type}</p>
                        <span>
                          <span
                            className="text-muted"
                            style={{ textDecoration: "line-through" }}
                          >
                            &#8377;{v.display_price}
                          </span>{" "}
                          &#8377;{v.actual_price}
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        handleChangeActivePackage(service.name, v.vehicle_type)
                      }
                    >
                      {v.active ? (
                        <FiCheckCircle size={20} color={COLORS.primary} />
                      ) : (
                        <FiCircle size={20} color={COLORS.primary} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {isLoggedIn ? <button
                disabled={!selectedPackage || service.name !== selectedPackage.packageName}
                className="btn text-light mt-2"
                style={{
                  backgroundColor: COLORS.primary,
                  borderRadius: "1.5rem",
                }}
                onClick={handleStartBooking}
              >
                Book Service
              </button> : <p className="my-2 fw-bold text-primary text-center">Please login to book this service.</p>}
              
            </div>
          ))}
        </div>
        <div className="col-1"></div>
      </div>
    </>
  );
};
