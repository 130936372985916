import { app } from "firebase-config";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";
const db = getFirestore(app);

export const getData = async (collectionName) => {
  const colData = collection(db, collectionName);
  const colSnap = await getDocs(colData);
  return colSnap.docs.length > 0
    ? colSnap.docs.map((doc) => doc.data())
    : false;
};

export const getDataByField = async (
  collectionName,
  fieldName,
  fieldValue,
  operator
) => {
  const q = query(
    collection(db, collectionName),
    where(fieldName, operator, fieldValue)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.length > 0 ? querySnapshot.docs.map(doc => {
    return {
      ...doc.data(),
      id: doc.id
    }
  }) : false;
};
