import { COLORS } from "constants/colors";
import polish from "assets/images/carpolish.jpg";
import clicktochat from "assets/images/click-to-chat.png";
export const AboutUs = () => (
  <div className="d-flex col-8 justify-content-between align-items-center">
    <div className="col-10 col-sm-5">
      <h1>
        <span
          style={{
            color: COLORS.primary,
            borderBottom: `1px solid ${COLORS.primary}`,
          }}
        >
          About
        </span>{" "}
        Fixuji
      </h1>
      <p className="my-4">
        Fixuji Car Wash is a unit of FIXUJI- DOMESTIC SERVICE which provides reliable, affordable, and Professional car washing services at your Doorstep!
      </p>
      <p className="my-4">
        We at Fixuji believe that your car is your identity. So we are here to help you to bring out 
        your A-game with your shiny and super-polished cars, that speak of luxury and class. Get Professional Service at the most affordable rates and that too at your Doorstep!
        
      </p>
      <p className="my-4">
        We have completed more than 3000 Doorstep services and served 1800+ customers till date.
      </p>
      <p className="mb-4">
        To know more about Fixuji, or to schedule a Doorstep booking with us, just send us a message on Whatsapp!
      </p>
      <a href="https://api.whatsapp.com/send?phone=918695971777&text=Hi">
        <img src={clicktochat} alt="playstore"  width={200} />
      </a>
    </div>
    <div className="col-2"></div>
    <div className="col-5">
      <img src={polish} alt="" height={400} className="w-100 d-none d-sm-block"/>
    </div>
  </div>
);
