import { COLORS } from "constants/colors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDataByField } from "utils/requests";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
export const MyProfile = () => {
  const { userInfo } = useSelector((state) => state.appReducer);
  const [displayName, setDisplayName] = useState(userInfo.displayName);
  const [email, setEmail] = useState(userInfo.email);
  const [userAddresses, setUserAddresses] = useState([]);
  const [showAddressModal, setShowAddressModal] = useState(false);

  useEffect(() => {
    if (userInfo.phoneNumber) {
      getUserAddresses();
    }
  }, [userInfo]);

  const getUserAddresses = async () => {
    const res = await getDataByField(
      "userinfo",
      "phoneNumber",
      userInfo.phoneNumber.toString(),
      "=="
    );
    if (res.addresses) {
      setUserAddresses(res.addresses);
    }
  };
  return (
    <div className="d-flex justify-content-center my-5">
      <Modal
        open={showAddressModal}
        onClose={() => setShowAddressModal(false)}
        center
        showCloseIcon={false}
      >
      </Modal>
      <div className="d-none d-sm-block col-1"></div>
      <div className="col-12 col-sm-10 p-3">
        <h1 className="fw-bold">
          <span style={{ color: COLORS.primary }}>Account </span>
          Details
        </h1>
        <p>Check or edit your profile details</p>
        <div>
          <div className="p-2 col-10 col-sm-4 rounded-3">
            <p className="fw-bold">Customer Name</p>
            <input
              className="form-control mb-3"
              value={displayName}
              onChange={(event) => setDisplayName(event.target.value)}
            />
            <p className="fw-bold">Customer Phone</p>
            <input
              className="form-control mb-3"
              value={userInfo.phoneNumber}
              disabled
            />
            <p className="fw-bold">Customer Email</p>
            <input
              className="form-control mb-3"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <button
              // disabled={!chosenCarMake || !chosenCarModel}
              className="btn text-light mt-2 col-6 col-sm-4"
              style={{
                backgroundColor: COLORS.primary,
                borderRadius: "1.5rem",
              }}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
