import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBo9j7JRIzazWsA977SYjlryVR883Uehqw",
  authDomain: "fixuji-customer.firebaseapp.com",
  databaseURL: "https://fixuji-customer.firebaseio.com",
  projectId: "fixuji-customer",
  storageBucket: "fixuji-customer.appspot.com",
  messagingSenderId: "367265764384",
  appId: "1:367265764384:web:8b579b280798ba19249845"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);