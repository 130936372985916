import React, { useEffect, useState } from "react";

import { Carousel } from "react-bootstrap";

import Slider1 from "assets/images/water_slider1@2x.jpg";
import about_car from "assets/images/about-car@2x.png";
import Car_pack from "assets/images/car-pack@2x.png";
import Booking_pack from "assets/images/booking-car@2x.png";
import {FiArrowRightCircle } from "react-icons/fi";
import playstore from "assets/images/g-store@2x.png";
import clicktochat from "assets/images/click-to-chat.png";
import man from "assets/images/whatsapp-booking.jpeg";

import Review from "assets/images/review@2x.jpg";
import "./styles.css";
import { COLORS } from "constants/colors";
import { getData, getDataByField } from "utils/requests";

import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { login, logout, setProfileData } from "redux/slices";
import axios from "axios";
import { useDispatch } from "react-redux";
import Modal from "react-responsive-modal";

export const Home = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState();
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();


  useEffect(() => {
    getAllServices();
    getAllReviews();
  }, []);


  const handleOtpSentOrVerify = async () => {
    if (!otpSent) {
      let fourDigitOtp = Math.floor(1000 + Math.random() * 9000);
      setGeneratedOtp(fourDigitOtp);
      let sms = `Dear user. Please use ${fourDigitOtp} as your OTP. Thanks and regards, FIXUJI.`;
      console.log(sms);
      await axios.post(
        "https://us-central1-fixuji-customer.cloudfunctions.net/sendCustomSms",
        {
          phoneNumber: mobileNumber,
          sms,
        }
      );
      setOtpSent(true);
    } else {
      if (+otp === +generatedOtp) {
        const response = await getDataByField(
          "userinfo",
          "phoneNumber",
          mobileNumber,
          "=="
        );
        const userData = response[0];
        console.log(userData);
        dispatch(login());
        dispatch(setProfileData(userData));
        localStorage.setItem("userId", userData.id);
        localStorage.setItem("userName", userData.displayName);
        localStorage.setItem("userEmail", userData.email);
        localStorage.setItem("userPhone", userData.phoneNumber);
        setOtpVerified(true);
      } else {
        //throw error
      }
    }
  };

  useEffect(() => {
    if (otpVerified) {
      setShowLoginModal(false);
    }
  }, [otpVerified]);

  const getAllServices = async () => {
    const allServices = await getDataByField(
      "services",
      "app_display",
      true,
      "=="
    );
    const servicesInclusive = allServices.map((service) => {
      const variants = [...service.variant];
      variants.sort((v1, v2) => +v1.sort_order - +v2.sort_order);
      return {
        ...service,
        startingPrice: variants[0].actual_price,
        image: service.name.includes("Bike") ? Car_pack : Car_pack,
      };
    });
    setServices(servicesInclusive);
  };

  const getAllReviews = async () => {
    const allReviews = await getData("reviews");
    setReviews(allReviews);
  };

  const Slider = () => (
    <Carousel fade>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Slider1}
          alt="First slide"
          height="auto"
          width="100%"
        />
        <Carousel.Caption className="sliderTest">
          <h3>Car Washing</h3>
          <h3 style={{ fontWeight: 700 }}>AFFORDABLE PACKS</h3>
          <h4 style={{ color: "#dee81a" }}>Starting just at ₹499</h4>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );

  const Welcome = () => {
    return (
      <div className="container-fluid p-2 p-sm-5">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex justify-content-start col-12 col-md-6 pt-3">
            <div className="px-5 pt-3">
              <h1 className="mb-4">
                <span
                  style={{
                    borderBottom: `2px solid ${COLORS.primary}`,
                    color: COLORS.primary,
                  }}
                >
                 Welcome {" "}
                </span>
                <span style={{ color: COLORS.primary }}>to</span> Fixuji
              </h1>
              <p>
                Professional Services at your Doorstep!
                {" "}
              </p>
              <p>
                {" "}
                From Regular Cleaning to Professional Detailing, Fixuji provides it all at your Doorstep. Protect your car from cheap chemicals, swirls and scratches. 
                 
              </p>
              <p>
              Call the experts today!
              </p>
              <button
                className="btn"
                style={{ borderRadius: "16px", color: COLORS.ash, backgroundColor: COLORS.primary }}
                onClick={() => navigate("/packages")}
              >
                Book Online 
                <FiArrowRightCircle className="ml-1 mt-0" size={24} />
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-end col-md-6 col-sm-12">
            <img
              src={about_car}
              alt=""
              height={500}
              width={950}
              className="welcomeCar d-none d-sm-block"
            />
          </div>
        </div>
      </div>
    );
  };

  const Service = () => {
    return (
      <div className="container-fluid pt-5 mt-3">
        <div className="d-flex justify-content-center text-center mb-2">
          <div className="d-flex flex-column service">
            <h1>
              <span style={{ color: COLORS.primary }}>Our </span>Services
            </h1>
            <p>Doorstep Car Wash Packages</p>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-evenly text-center px-5">
          {services.map((service) => (
            <div
              className="shadow card rounded-2 my-2"
              style={{ width: "300px" }}
              key={service.name}
              onClick={() => {
                if (isLoggedIn) {
                  navigate("/packages/new-booking", {
                    state: {
                      selectedPackage: {
                        packageName: service.name,
                      },
                    },
                  });
                } else {
                 setShowLoginModal(true);
                }
              }}
              role="button"
            >
              <div className="card-body">
                <p className="card-title">{service.description}</p>
                <h4 className="card-title" style={{ height: "70px" }}>
                  {service.name}
                </h4>
                <img
                  src={service.image}
                  alt=""
                  height={50}
                  width={100}
                  className="carImage"
                />
                <p className="card-text text-secondary">{service.type}</p>
                <p className="card-text">
                  Starting from{" "}
                  <span className="fw-bold">
                    &#8377;{service.startingPrice}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const Booking = () => {
    return (
      <div className="container-fluid">
        <div className="d-flex justify-content-center col-md-12">
          <div className="col-md-7">
            <img
              className="booking_car_img"
              src={Booking_pack}
              alt=""
              height={350}
              width={800}
            />
          </div>

          <div className="col-md-7 mt-5">
            <div className="booking_form">
              <h1>
                <span>Quick </span>Booking
              </h1>
              <p>Ouick Booking Now</p>
            </div>
            <div className="booking_form_button">
              <button className="button_active" type="button">
                VEHICLE
              </button>
              <button>PACKAGE</button>
              <button>LOCATION</button>
              <button>ADDONS</button>
            </div>
            <div className="mt-2 text-start">
              <h5>What kind of car do you drive?</h5>
            </div>
            <div className="d-flex row flex-row mt-4 text-start col-md-12">
              <form action="">
                <div className="col-md-4 mt-2 formStyle">
                  <label className="" htmlFor="">
                    VEHICLE TYPE
                  </label>
                  <div>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Hatchback</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="col-md-4 mt-2 me-4 formStyle">
                    <label className="" htmlFor="">
                      CAR MAKE
                    </label>
                    <div>
                      <input class="form-control" type="text" />
                    </div>
                  </div>

                  <div className="col-md-4 mt-2 formStyle">
                    <label className="" htmlFor="">
                      CAR MAKE
                    </label>
                    <div>
                      <input class="form-control" type="text" />
                    </div>
                  </div>
                </div>
                <p>Can not remember? Leave it blank. You update later. </p>
                <div className="formButton">
                  <button>Next</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const VideoSection = () => {
    return (
      <div className="container-fluid pt-5">
        <div className="d-flex justify-content-center text-center">
          <div className="d-flex flex-column service">
            <h1>
              <span style={{ color: COLORS.primary }}>Watch </span>A Video
            </h1>
            <p>Professional and Premium Services</p>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-evenly text-center px-4">
          <iframe title="CarWashVideo" 
          src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Ffixuji%2Fvideos%2F1184007289223586%2F&show_text=false&width=560&t=0" 
          width={450} height={314} style={{border: `none`, overflow:`hidden`}} scrolling="yes" frameBorder="0" allowFullScreen={true} 
          allow={`autoplay`, `clipboard-write`, `encrypted-media`, `picture-in-picture`, `web-share`} allowFullScreen={true}>
          </iframe>
        </div>
      </div>
    )};

  const CustomerSection = () => {
    return (
      <div className="container-fluid d-flex flex-wrap justify-content-center my-5 pt-4">
        <div className="col-12 col-md-8 d-flex flex-wrap">
          <div className="col-md-6 text-start rewiew_section">
            <h1 className="ml-3 text-center">
              <span>Customers</span> Review
            </h1>
            <p className="ml-3 text-center">Our Customers Feedback</p>
            <div className="row col-md-12 customers_slider">
              <Carousel variant="dark" interval={null}>
                {reviews.map((rev) => (
                  <Carousel.Item className="p-2 rounded-3" key={rev.name}>
                    <div className="p-3 d-flex flex-wrap shadow-sm justify-content-between align-items-center">
                      <img
                        src={rev.url}
                        alt="cust-img"
                        height={100}
                        width={100}
                        style={{
                          border: `2px solid ${COLORS.primary}`,
                          borderRadius: "16px",
                        }}
                      />
                      <div className="text-start">
                        <p className="card-title text-secondary fw-bold fs-3">
                          {rev.name}
                        </p>
                        <ReactStars
                          count={5}
                          value={rev.rating}
                          edit={false}
                          size={24}
                          activeColor={COLORS.primary}
                          isHalf={true}
                        />
                        <p className="card-title">{rev.review}</p>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="col-md-6 text-start rewiew_section_image">
            <img
              src={Review}
              alt=""
              height={400}
              width={600}
              className="d-none d-md-block"
            />
          </div>
        </div>
      </div>
    );
  };
  const DownloadApp = () => {
    return (
      <div className="d-flex flex-wrap justify-content-center">
        <div className="col-12 col-sm-3 text-center mt-2">
          <h1 style={{ color: COLORS.primary }}>BOOK NOW</h1>
          <h3 className="text-secondary fw-bold my-4">On Whatsapp!</h3>
          <img src={man} alt="" width={300} />
        </div>
        <div className="col-12 col-sm-3 p-4">
          
          <p> 
            Schedule a Doorstep service directly from Whatsapp!
          </p>
          <p className="mb-4">
          Chat with our executives on WhatsApp and stay informed on latest offers, plans and packages.
          </p>
          <a href="https://api.whatsapp.com/send?phone=918695971777&text=Hi">
            <img src={clicktochat} alt="playstore"  width={200} />
          </a>
        </div>
        
        {/* <div className="col-12 col-sm-3 p-4">
          <h1 style={{ color: COLORS.primary }}>DOWNLOAD</h1>
          <h3 className="text-secondary fw-bold my-4">Our App!</h3>
          <p className="mb-4">
          To know more about Fixuji Services, or to schedule a Doorstep booking with us, Download our App today and make your cars shine as new!
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.fixuji.customer">
            <img src={playstore} alt="playstore" height={50} width={200} />
          </a>
        </div>
        <div className="col-12 col-sm-3 text-center">
          <img src={man} alt="" width={300} />
        </div> */}
      </div>
    )
  }
  return (
    <>
    <Modal
        open={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        center
        showCloseIcon={false}
      >
        <div className="rounded-3 p-2">
          <div className="d-flex justify-content-between flex-wrap">
            <h3 className="modal-title text-center">Login</h3>
            <button
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowLoginModal(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="my-4">
            <h5 className="text-secondary">Enter Mobile Number</h5>
            <input
              type="text"
              className="form-control"
              value={mobileNumber}
              onChange={(event) => {
                setMobileNumber(event.target.value.replace(/[^0-9]/g, ""));
              }}
              maxLength={10}
            />
          </div>
          {otpSent && (
            <div className="my-4">
              <h5 className="text-secondary">Enter OTP</h5>
              <input
                type="text"
                className="form-control"
                value={otp}
                onChange={(event) => {
                  setOtp(event.target.value.replace(/[^0-9]/g, ""));
                }}
                maxLength={4}
              />
            </div>
          )}

          <div className="my-4">
            {otpSent ? (
              <button
                disabled={otp.length < 4}
                type="button"
                className="btn p-2 rounded-3 text-light fw-bold"
                style={{ backgroundColor: COLORS.primary }}
                onClick={handleOtpSentOrVerify}
              >
                Login
              </button>
            ) : (
              <button
                disabled={mobileNumber.length < 10}
                type="button"
                className="btn p-2 rounded-3 text-light fw-bold"
                style={{ backgroundColor: COLORS.primary }}
                onClick={handleOtpSentOrVerify}
              >
                Send OTP
              </button>
            )}
          </div>
        </div>
      </Modal>
      <Slider />
      <Welcome />
      <Service />
      <CustomerSection />
      <VideoSection/>
      {/*<Booking /> */}
      
      <DownloadApp />
    </>
  );
};
