import { Home } from "pages/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "components/Layout/Layout";
import { Booking } from "pages/Booking/Booking";
import { NewBooking } from "pages/Booking/NewBooking/NewBooking";
import { About } from "pages/About/About";
import { Profile } from "pages/Profile/Profile";
import { OrderHistory } from "pages/OrderHistory/OrderHistory";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, setProfileData } from "redux/slices";
import { MyProfile } from "pages/Profile/MyProfile/MyProfile";
import { ProtectedRoutes } from "components/ProtectedRoutes/ProtectedRoutes";
import LoadingOverlay from "react-loading-overlay";

function App() {
  const dispatch = useDispatch();
  const { showSpinner } = useSelector((state) => state.appReducer);
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const userPhone = localStorage.getItem("userPhone");
    const userEmail = localStorage.getItem("userEmail");
    const userData = {
      displayName: userName,
      email: userEmail,
      phoneNumber: userPhone,
      id: userId,
    };
    dispatch(setProfileData(userData));
    if (userId && userName && userPhone && userEmail) {
      dispatch(login());
    } else {
      dispatch(logout());
    }
  }, []);

  return (
    <BrowserRouter>
      <LoadingOverlay active={showSpinner} spinner text="Loading...">
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/packages" element={<Booking />} />
            <Route
              path="/packages/new-booking"
              element={
                <ProtectedRoutes>
                  <NewBooking />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/orders"
              element={
                <ProtectedRoutes>
                  <OrderHistory />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoutes>
                  <Profile />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/profile/my-profile"
              element={
                <ProtectedRoutes>
                  <MyProfile />
                </ProtectedRoutes>
              }
            />
            <Route path="/aboutus" element={<About />} />
          </Routes>
        </Layout>
      </LoadingOverlay>
    </BrowserRouter>
  );
}

export default App;
