import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getData, getDataByField } from "utils/requests";
import placeholder from "assets/images/placeholder.jpg";
import polish from "assets/images/carpolish.jpg";
import { COLORS } from "constants/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaInfoCircle,
} from "react-icons/fa";
import axios from "axios";
import Modal from "react-responsive-modal";
import { useDispatch } from "react-redux";
import { changeSpinnerStatus } from "redux/slices";

export const NewBooking = () => {
  const { state } = useLocation();
  const { userInfo } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const packageData = state.selectedPackage;
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [userAddresses, setUserAddresses] = useState();
  const [carModels, setCarModels] = useState([]);
  const [chosenCarModel, setChosenCarModel] = useState("Suzuki");
  const [chosenCarMake, setChosenCarMake] = useState();
  const [chosenPackage, setChosenPackage] = useState();
  const [chosenVariant, setChosenVariant] = useState();
  const [chosenDate, setChosenDate] = useState(new Date());
  const [electricityOpted, setElectricityOpted] = useState(false);
  const [chosenAddons, setChosenAddons] = useState([]);
  const [activeTab, setActiveTab] = useState("vehicle");
  const [showAddons, setShowAddons] = useState();
  const [lastAddonIndex, setLastAddonIndex] = useState(1);
  const [allApplicableAddons, setAllApplicableAddons] = useState([]);
  const [totalAddonCharges, setTotalAddonCharges] = useState(0);
  const [enteredAddress, setEnteredAddress] = useState();
  const [services, setServices] = useState([]);
  const [variants, setVariants] = useState([]);
  const [locations, setLocations] = useState([]);
  const [chosenLocation, setChosenLocation] = useState();
  const [chosenPin, setChosenPin] = useState();

  console.log(packageData);

  const [bookingState, setBookingState] = useState([
    {
      label: "vehicle",
      displayText: "Car Model",
      active: true,
    },
    {
      label: "location",
      displayText: "Location",
      active: false,
    },
    {
      label: "addons",
      displayText: "Addons",
      active: false,
    },
  ]);

  useEffect(() => {
    if (packageData) {
      getAllServices();
      getCarModels();
      getLocations();
    }
  }, [packageData]);

  const getLocations = async () => {
    const tempLocations = await getData("serviceable_locations");
    setLocations(tempLocations);
    setChosenLocation(tempLocations[0]);
    setChosenPin(tempLocations[0].zipCodes[0]);
  };

  useEffect(() => {
    if (userInfo.phoneNumber) {
      getUserAddresses();
    }
  }, [userInfo]);

  useEffect(() => {
    const tempTabs = bookingState.map((bks) => {
      return bks.label === activeTab
        ? { ...bks, active: true }
        : { ...bks, active: false };
    });
    setBookingState(tempTabs);
  }, [activeTab]);

  useEffect(() => {
    if (chosenAddons.length > 0) {
      const totalAddonPrice = chosenAddons.reduce((prev, curr) => {
        return +prev + +curr.actual_price;
      }, 0);
      setTotalAddonCharges(totalAddonPrice);
    }
  }, [chosenAddons]);

  const getUserAddresses = async () => {
    const res = await getDataByField(
      "userinfo",
      "phoneNumber",
      userInfo.phoneNumber.toString(),
      "=="
    );
    if (res.addresses) {
      setUserAddresses(res.addresses);
      setEnteredAddress(res.addresses[0]);
    }
  };

  const getAllServices = async () => {
    const allServices = await getDataByField(
      "services",
      "app_display",
      true,
      "=="
    );
    setServices(allServices);
    const chosenService = allServices.find(
      (s) => s.name === packageData.packageName
    );
    setChosenPackage(chosenService);
    setVariants(chosenService?.variant);
    const chosenVehicle = chosenService.variant.find(
      (v) => v.vehicle_type === packageData.vehicleType
    );
    setChosenVariant(chosenVehicle ? chosenVehicle : chosenService?.variant[0]);
    const applicableAddons = chosenService.addons; /*.filter(
      (addon) => addon.vehicle_type === packageData.vehicleType
    );*/
    setAllApplicableAddons(
      applicableAddons.map((addon, idx) => {
        return { ...addon, active: false, id: idx, showTooltip: false };
      })
    );
    setShowAddons(
      applicableAddons
        .map((addon, idx) => {
          return { ...addon, active: false, id: idx };
        })
        .slice(0, 2)
    );
  };

  const getCarModels = async () => {
    const carModels = await getData("car_models");
    setChosenCarModel(carModels[0].manufacturer);
    setCarModels(carModels);
  };

  const getOrderDateObject = (selectedDate) => {
    console.log("date::", selectedDate);
    const date = new Date(selectedDate);
    console.log("date::", date);
    const monthName = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dateObj = {
      date: date.getDate(),
      day: dayName[date.getDay()],
      full_date: date.toISOString(),
      month: monthName[date.getMonth()],
    };
    return dateObj;
  };

  const handleChangeActiveTab = async () => {
    if (activeTab === "vehicle") {
      setActiveTab("location");
    } else if (activeTab === "location") {
      setActiveTab("addons");
    } else {
      let user = await getDataByField(
        "userinfo",
        "phoneNumber",
        userInfo.phoneNumber,
        "=="
      );
      let payload = {
        service: chosenPackage,
        variant: chosenVariant,
        addons: chosenAddons,
        bookingDate: chosenDate,
        createdDate: new Date(),
        total:
          +chosenVariant.actual_price +
          (electricityOpted ? +chosenPackage.electricity_charges : 0) +
          +totalAddonCharges,
        vehicle: {
          brand: chosenCarMake,
          model: chosenCarModel,
          type: "CAR",
        },
        location: {
          address_type: "HOME",
          lat: 0,
          lng: 0,
          full_address: enteredAddress,
        },
        status: "CREATED",
        user_id: user[0].id,
        userData: {
          displayName: userInfo.displayName,
          phoneNumber: userInfo.phoneNumber,
        },
        serviceMode: "DOORSTEP",
        referalCode: "",
        couponCode: "",
        department: "Siliguri",
        date: getOrderDateObject(chosenDate),
      };
      dispatch(changeSpinnerStatus(true));
      let { data } = await axios.post(
        "https://us-central1-fixuji-customer.cloudfunctions.net/orderCreatedTrigger",
        payload
      );
      dispatch(changeSpinnerStatus(false));
      if (data.status === 200) {
        setModalMessage(
          `Your service has been booked with id ${data.orderId}.`
        );
        setShowModal(true);
      } else {
        setModalMessage(`Something went wrong. Please try again.`);
        setShowModal(true);
      }
    }
  };

  const handlePreviousTab = () => {
    if (activeTab === "location") {
      setActiveTab("vehicle");
    } else if (activeTab === "addons") {
      setActiveTab("location");
    }
  };

  const handleChangeAddonsSection = (eventType) => {
    if (eventType === "prev") {
      if (lastAddonIndex - 2 >= 0) {
        setShowAddons(
          allApplicableAddons.slice(lastAddonIndex - 2, lastAddonIndex)
        );
        setLastAddonIndex(lastAddonIndex - 1);
      }
    } else {
      if (lastAddonIndex + 2 <= allApplicableAddons.length) {
        setShowAddons(
          allApplicableAddons.slice(lastAddonIndex, lastAddonIndex + 2)
        );
        setLastAddonIndex(lastAddonIndex + 1);
      }
    }
  };

  const handleSetActiveAddon = (id) => {
    const applicableAddons = allApplicableAddons.map((addon) => {
      return addon.id === id ? { ...addon, active: !addon.active } : addon;
    });
    setAllApplicableAddons(applicableAddons);
    setChosenAddons(applicableAddons.filter((addon) => addon.active));
    setShowAddons(
      showAddons.map((addon) => {
        return addon.id === id ? { ...addon, active: !addon.active } : addon;
      })
    );
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        center
        showCloseIcon={false}
      >
        <div className="rounded-3 p-2">
          <div className="d-flex justify-content-between flex-wrap">
            <h3 className="modal-title text-center">Order Placed</h3>
            <button
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowModal(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="my-4">
            <h5 className="text-secondary">{modalMessage}</h5>
          </div>
          <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn p-2 rounded-3 fw-bold mr-2"
            style={{
              border: `1px solid ${COLORS.primary}`,
              borderRadius: "1.5rem",
              color: COLORS.primary,
              fontSize: "14px",
            }}
            onClick={() => {
              setShowModal(false);
              navigate("/");
            }}
          >
            Go to Home
          </button>
          <button
            type="button"
            className="btn p-2 rounded-3 text-light fw-bold"
            style={{
              borderRadius: "1.5rem",
              backgroundColor: COLORS.primary,
              fontSize: "14px",
            }}
            onClick={() => {
              setShowModal(false);
              navigate("/orders");
            }}
          >
            View Order
          </button>
          </div>
          
        </div>
      </Modal>
      <img src={placeholder} style={{ width: "100vw" }} alt="dummy" />
      <div className="d-flex flex-wrap justify-content-center my-5">
        <div className="col-1"></div>
        <div className="col-4 mr-2 d-none d-sm-block">
          <img src={polish} alt="" height={400} width={400} />
        </div>
        <div className="col-10 col-sm-6">
          <div className="p-2">
            <h1>
              <span style={{ color: COLORS.primary }}>Quick </span>Booking
            </h1>
          </div>
          <div className="d-flex my-2">
            {bookingState.map((bks) => (
              <div
                key={bks.label}
                className="text-center p-2 m-2"
                style={{
                  backgroundColor: bks.active ? "black" : COLORS.shadow,
                  borderRadius: "16px",
                }}
              >
                <p
                  className="mb-0 text-uppercase fw-bold"
                  style={{
                    color: bks.active ? COLORS.shadow : "black",
                    fontSize: "12px",
                  }}
                >
                  {bks.displayText}
                </p>
              </div>
            ))}
          </div>

          <div className="my-4 text-start">
            {activeTab === "vehicle" && (
              <div className="row">
                <div className="col-10">
                  <h6>Package selected</h6>
                  <div className="my-3 ">
                    <select
                      className="form-select"
                      onChange={(event) => setChosenPackage(event.target.value)}
                      value={chosenPackage?.name}
                    >
                      {services.map((s) => (
                        <option
                          key={s.name}
                          value={s.name}
                          className="text-capitalize"
                        >
                          {s.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-10">
                  <h6 className="">Car Type selected</h6>
                  <div className="my-3 ">
                    <select
                      className="form-select"
                      onChange={(event) => setChosenVariant(event.target.value)}
                      value={chosenVariant?.vehicle_type}
                    >
                      {variants.map((v) => (
                        <option
                          key={v.vehicle_type}
                          value={v.vehicle_type}
                          className="text-capitalize"
                        >
                          {v.vehicle_type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                
                <div className="d-flex flex-wrap align-items-center col-12 col-sm-6">
                  <div className="col-10 col-sm-5">
                    <h6 className="mt-2">Car Make</h6>
                    <div>
                      <select
                        className="form-select"
                        onChange={(event) =>
                          setChosenCarModel(event.target.value)
                        }
                      >
                        {carModels.map((model) => (
                          <option
                            key={model.manufacturer}
                            value={model.manufacturer}
                            className="text-capitalize"
                          >
                            {model.manufacturer}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-10 col-sm-5 mt-2 mt-sm-0">
                    <h6 className="mt-2">
                      <span style={{ color: "red" }}>* </span>Car Model
                    </h6>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        value={chosenCarMake}
                        onChange={(event) =>
                          setChosenCarMake(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === "location" && (
              <div>
                <h5 className="mb-4">Select Booking Date and Location</h5>
                <div className="my-3">
                  <p style={{ fontSize: "16px" }}>
                    <span style={{ color: "red" }}>* </span>Select Booking Date
                  </p>
                  <DatePicker
                    selected={chosenDate}
                    onChange={(date) => setChosenDate(date)}
                    dropdownMode="select"
                    className="form-control w-75"
                  />
                </div>
                <div className="my-3 w-75">
                  <p style={{ fontSize: "16px" }}>
                    <span style={{ color: "red" }}>* </span>Select Zone
                  </p>
                  <select
                    className="form-select"
                    value={chosenLocation?.city}
                    onChange={(event) => setChosenLocation(event.target.value)}
                  >
                    {locations.map((loc) => (
                      <option key={loc} value={loc} className="text-capitalize">
                        {loc.city}
                      </option>
                    ))}
                  </select>
                </div>
                {chosenLocation && (
                  <div className="my-3 w-75">
                    <p style={{ fontSize: "16px" }}>
                      <span style={{ color: "red" }}>* </span>Select Pin
                    </p>
                    <select
                      className="form-select"
                      value={chosenPin}
                      onChange={(event) => setChosenPin(event.target.value)}
                    >
                      {chosenLocation.zipCodes.map((zip) => (
                        <option
                          key={zip}
                          value={zip}
                          className="text-capitalize"
                        >
                          {zip}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {chosenLocation && chosenPin && (
                  <div className="my-3">
                    <div className="p-2">
                      <p style={{ fontSize: "16px" }}>
                        <span style={{ color: "red" }}>* </span>Enter Your
                        Address
                      </p>
                      <textarea
                        className="form-control"
                        value={enteredAddress}
                        onChange={(event) =>
                          setEnteredAddress(event.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            )}
            {activeTab === "addons" && (
              <>
              {chosenPackage &&
                  chosenPackage.addons &&
                  chosenPackage.addons.length > 0 && (
                    <div className="w-100 w-sm-50 mt-4">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h6>Select Addons (Optional)</h6>
                        {allApplicableAddons.length > 2 && (
                          <div className="d-flex">
                            <FaArrowCircleLeft
                              size={24}
                              color={COLORS.primary}
                              className="mr-4"
                              onClick={() => handleChangeAddonsSection("prev")}
                              role="button"
                            />
                            <FaArrowCircleRight
                              size={24}
                              color={COLORS.primary}
                              onClick={() => handleChangeAddonsSection("next")}
                              role="button"
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-between">
                        {showAddons &&
                          showAddons.map((addOn) => (
                            <div
                              className="shadow rounded-3 p-0"
                              style={{ width: "48%" }}
                              key={addOn.id}
                            >
                              <img
                                src={addOn.addon_img}
                                alt="addon"
                                height={120}
                                className="w-100 rounded-3"
                              />
                              <div className="p-2">
                                <div
                                  className="my-3 text-center"
                                  style={{ height: "35px" }}
                                >
                                  <span
                                    className="fs-6"
                                    style={{ height: "50px" }}
                                  >
                                    {addOn.addon_name}
                                  </span>
                                  <span>
                                    <FaInfoCircle size={15} className="ml-1" onClick={() => {
                                      const addOns = showAddons.map((a) => {
                                        return a.addon_name === addOn.addon_name ? {...a, showTooltip: !a.showTooltip} : {...a, showTooltip: false}
                                      });
                                      setShowAddons(addOns);
                                    }}/>
                                  </span>
                                  <p
                                    hidden={!addOn.showTooltip}
                                    style={{
                                      position: "relative",
                                      backgroundColor: "black",
                                      fontWeight: 'bold',
                                      fontSize: '12px',
                                      color: "#fff",
                                      padding: "15px",
                                      zIndex: 1,
                                      borderRadius: '16px'
                                    }}
                                  >
                                    {addOn.addon_desc}
                                  </p>
                                </div>
                                <hr />
                                <div className="justify-content-between align-items-center">
                                  <div className="d-flex my-1 my-sm-3 mx-1">
                                    <div className="col-5">
                                    <span
                                        style={{
                                          fontSize: "14px",
                                          textDecorationLine: "line-through",
                                        }}
                                        className="text-secondary mr-2"
                                      >
                                        {"    "}
                                        &#8377;{addOn.display_price}
                                      </span>
                                      <br></br>
                                      <span
                                        style={{ color: COLORS.primary, fontSize: "20px" }}
                                        className="fw-bold"
                                      >
                                        &#8377;{addOn.actual_price}
                                      </span>
                                    </div>
                                    <div className="col-7">
                                    <button
                                    className="float-right btn rounded-2 text-center text-light mt-3 mt-sm-0"
                                    style={{ backgroundColor: COLORS.primary }}
                                    onClick={() =>
                                      handleSetActiveAddon(addOn.id)
                                    }
                                  >
                                    {addOn.active ? "Remove" : "Add"}
                                  </button>
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                <div className="mt-5 shadow rounded-3 p-4 w-100 w-sm-50">
                  <h6 className="mb-4">Booking Summary</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="fw-bold">{packageData.packageName}</h5>
                      <h6 style={{ color: COLORS.primary }}>
                        {packageData.vehicleType}
                      </h6>
                    </div>
                    <h6 className="fw-bold">
                      &#8377;{chosenVariant.actual_price}
                    </h6>
                  </div>
                  {electricityOpted && (
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-bold fs-6">Electricity Charges</h5>
                      <h6 className="fw-bold">
                        &#8377;{chosenPackage.electricity_charges}
                      </h6>
                    </div>
                  )}
                  {chosenAddons && chosenAddons.length > 0 && (
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-bold fs-6">
                        Addons ({chosenAddons.length})
                      </h5>
                      <h6 className="fw-bold">&#8377;{totalAddonCharges}</h6>
                    </div>
                  )}
                  <hr />
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-bold">Total</h5>
                    <h6 className="fw-bold">
                      &#8377;
                      {+chosenVariant.actual_price +
                        (electricityOpted
                          ? +chosenPackage.electricity_charges
                          : 0) +
                        +totalAddonCharges}
                    </h6>
                  </div>
                </div>
                <div className="form-check my-4 ml-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={electricityOpted}
                    onChange={() => setElectricityOpted(!electricityOpted)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    Need Electricity and Water Supply
                  </label>
                </div>
                
              </>
            )}
            <div className="d-flex w-100 mt-4">
              {activeTab !== "vehicle" && (
                <button
                  className="btn mt-2 mr-2 col-5 col-sm-3"
                  style={{
                    border: `1px solid ${COLORS.primary}`,
                    borderRadius: "1.5rem",
                    color: COLORS.primary,
                    fontSize: "14px",
                  }}
                  onClick={handlePreviousTab}
                >
                  Previous
                </button>
              )}

              <button
                disabled={
                  (activeTab === "vehicle" &&
                    (!chosenCarMake || !chosenCarModel)) ||
                  (activeTab === "location" &&
                    (!chosenDate ||
                      !chosenLocation ||
                      !chosenPin ||
                      !enteredAddress))
                }
                className="btn text-light mt-2 px-4 col-6 col-sm-3"
                style={{
                  backgroundColor: COLORS.primary,
                  borderRadius: "1.5rem",
                  fontSize: "15px",
                }}
                onClick={handleChangeActiveTab}
              >
                {activeTab !== "addons"
                  ? "Next"
                  : `Confirm Booking`}
              </button>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
    </>
  );
};
