import { createSlice } from "@reduxjs/toolkit";
export const appSlice = createSlice({
    name: "app",
    initialState: {
      isLoggedIn: false,
      userInfo: {},
      showSpinner: false
    },
    reducers: {
      login: (state) => {
        state.isLoggedIn = true;
      },
      logout: (state) => {
        state.isLoggedIn = false;
      },
      setProfileData: (state, action) => {
        state.userInfo = action.payload;
      },
      changeSpinnerStatus: (state, action) => {
        state.showSpinner = action.payload
      }
    },
  });
  
  export const { login, logout, setProfileData, changeSpinnerStatus } = appSlice.actions;